import React from "react"
import "../components/layout.css";

import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import SEO from "../components/SEO";

const NotFoundPage = () => (
  <React.Fragment>
    <NavBar />
      <SEO title="404: Not found" />
      <div className="page-container 404-page">
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </div>
    <Footer />
  </React.Fragment>
)

export default NotFoundPage
